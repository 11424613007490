import React from "react";
import Script from '@gumgum/react-script-tag';
import Meta from "../Meta";
import { useEvent } from '../../hooks';
import { useRouter } from '../../util/router';
import OGDialogBox from "./OGDialogBox";
import { isMobile } from 'react-device-detect';
import { ogLog } from "../../util/db";
import OGConfirmationModal from "./OGConfirmationModal";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import '../../assets/styles.css';
const opengridCanvasStyle = {
    width: '100%',
    height: '100vh',
    minHeight: '100vh',
    backgroundImage: isMobile ? 'url("https://res.cloudinary.com/dq5lw8oy1/image/upload/v1653426761/OpenGridComplex/mobile_loader_20years_complex_jyfnsq.jpg")'
        : 'url("https://res.cloudinary.com/dq5lw8oy1/image/upload/e_blur:369/v1653077455/OpenGridComplex/OG_Complex_LoadingscreenImage_web_vgopqw.jpg")',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
}

const ogContainerStyle = {
    margin: 'auto',
    position: 'fixed',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: '100 !important',
    height: '100vh',
    width: '100vw',
}

const buildConfig = (filename) => {
    return {
        dataUrl: `./BuildDay2/Build/${filename}.data`,
        frameworkUrl: `./BuildDay2/Build/${filename}.framework.js`,
        codeUrl: `./BuildDay2/Build/${filename}.wasm`,
        loaderUrl: `./BuildDay2/Build/Build.loader.js`,
        streamingAssetsUrl: `./BuildDay2/StreamingAssets`,
        companyName: "Open Grid",
        productName: "opengrid_retail",
        productVersion: "0.2",
    }
}

const OpenGridContainer = (props) => {
    const [build, setBuild] = React.useState(null);
    const [openGridHasLoaded, setOpenGridHasLoaded] = React.useState(false);
    const [checkout, setCheckout] = React.useState(null);
    const [checkoutId, setCheckoutID] = React.useState(null);
    const [checkoutUrl, setCheckoutUrl] = React.useState(null);
    const [justExited, setJustExited] = React.useState(false);
    const [initialSceneLoad, setInitialSceneLoad] = React.useState(true);

    const router = useRouter();

    const isIos154 = navigator.userAgent.match('15_4');
    const isWebview = navigator.userAgent.match('wv');


    React.useEffect(() => {
        if (openGridHasLoaded) {
            const mobileMode = isMobile ? "1" : "0";
            build.SendMessage("GameController", "SetMobileMode", mobileMode);
        }
    }, [openGridHasLoaded]);

    React.useEffect(() => {
        if (router.pathname === '/') refocus();
    }, [router.pathname]);
    
    useEvent('loaded_ui_manager', () => {
        ogLog('loaded_ui_manager');
        setOpenGridHasLoaded(true);
    });
    useEvent('load_ready', () => {
        ogLog('load_ready')
        if (initialSceneLoad)
        {
            build.SendMessage("LoadingScreen", "SelectScene", 'gallery');
            setInitialSceneLoad(false);
        }
    });

    useEvent('request_gyro', () => {
        if (!isWebview) {
            build.SendMessage("GameController", "EnableGyro");
        }
    });
    useEvent('return_to_website', () => {
        ogLog('return_to_website');
        location.replace('https://complexland.com');
    }); 

    

    const refocus = () => { if (build) build.SendMessage("GameController", "FocusCanvas", "1") };
    const unfocus = () => { if (build) build.SendMessage("GameController", "FocusCanvas", "0") };
    
    const onLoad = () => {
        if (!isIos154) setTimeout(() =>
            window.createUnityInstance(document.getElementById('opengrid-canvas'), buildConfig('Build'))
                .then((buildInstance) => setBuild(buildInstance))
                .then(ogLog('initial_load'))
                .catch((message) => {
                    console.log(message);
                    alert(message);
                }),
            500);
    }
    
    
    return (<>
        <Meta title="20 Years of Complex Pop-Up | Merch and Iconic Covers" />
        
        <Script type="text/javascript" src={`${window.location.protocol}//${window.location.host}/BuildDay2/Build/Build.loader.js`} onLoad={() => onLoad()} />
        <div style={ogContainerStyle}>
            <canvas id="opengrid-canvas" style={opengridCanvasStyle} />
        </div>
        {isIos154 && <OGConfirmationModal title="This experience requires an up-to-date OS to run properly."
            link="https://shop.complex.com/collections/20-years-of-complex-maneki-neko-designed-by-nigo" />
        }
        <OGDialogBox unfocus={unfocus} refocus={refocus}
            client={props?.client}
            checkoutId={checkoutId}
            setCheckoutID={setCheckoutID}
            checkout={checkout}
            setCheckout={setCheckout}
            checkoutUrl={checkoutUrl}
            setCheckoutUrl={setCheckoutUrl}
            // setMessage={setMessage}
        />
            {/* <Box 
                style={{ position: 'absolute', bottom: 0, zIndex: 10000 }} >
                <button style={{  zIndex: 10000 }} onClick={() => dispatchEvent(new Event('return_to_website'))}>Complexland</button>
                <button style={{  zIndex: 10000 }} onClick={() => dispatchEvent(new Event('open_web_cart'))}>Cart</button>
                <button style={{  zIndex: 10000 }} onClick={() => dispatchEvent(new Event('ViewInfoModal_C01'))}>Info</button>
                <button style={{ zIndex: 10000 }} onClick={() => dispatchEvent(new Event('ViewInfoModal_S01'))}>Product 1</button>
                <button style={{ zIndex: 10000 }} onClick={() => dispatchEvent(new Event('ViewInfoModal_S02'))}>Product 2</button>
                <button style={{ zIndex: 10000 }} onClick={() => dispatchEvent(new Event('ViewInfoModal_S03'))}>Product 3</button>
            <button style={{ zIndex: 10000 }} onClick={() => dispatchEvent(new Event('ViewInfoModal_S04'))}>Product 4</button>
            <button style={{  zIndex: 10000 }} onClick={() => dispatchEvent(new Event('ViewOpenGrid'))}>OpenGrid</button>
                <button style={{  zIndex: 10000 }} onClick={() => dispatchEvent(new Event('ViewTerms'))}>Terms</button>
                <button style={{  zIndex: 10000 }} onClick={() => dispatchEvent(new Event('ViewPrivacyPolicy'))}>Privacy</button>
            </Box> */}
    </>);
}

export default OpenGridContainer;

       
   