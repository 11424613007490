import React from 'react';
import { isMobile } from 'react-device-detect';
// import SimpleImageSlider from 'react-simple-image-slider';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
// import { useProduct } from '../../util/db';
import { Carousel } from 'react-responsive-carousel';

const covers = {
    "ViewInfoModal_C01": {
        title: "THE WEEKEND",
        date: "AUG/SEPT 2013",
        photographer: 'DAVID BLACK',
        description: "This was The Weeknd’s first ever interview. The photos were shot at Good Luck Bar, a popular L.A. dive bar that closed in 2019, where The Notorious B.I.G. was also photographed for his Ego Trip cover a few months before his passing.",
        images: [
            {
                url: 'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1652392182/OpenGridComplex/Weeknd_1_bamjlc.jpg'
            },
            {
                url: 'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1652392182/OpenGridComplex/Weeknd_2_dmtflz.jpg'
            },
            {
                url: 'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1652392182/OpenGridComplex/Weeknd_3_kmwc98.jpg'
            }
        ]
    },
    
    
    "ViewInfoModal_C02": {
        title: "KOBE BRYANT",
        date: "FEB/MAR 2009",
        photographer: 'ROBYN TWOMEY',
        description: "Kanye West was originally supposed to grace the cover of our February/March 2009 \
        Issue, but that was later scrapped after agreeing that coming off our Guest Edited Style \
        issue the previous year, this didn’t up the ante. A week later, we shot Kobe Bryant in \
        Sacramento, where the Lakers were playing the next day. \
        Kobe’s music request on the set of the shoot? Wu-Tang Clan’s 36 Chambers and A Tribe \
        Called Quest’s Midnight Marauders. ",
        images: [
            {
                url: 'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1652392193/OpenGridComplex/Kobe_1_gnyyin.jpg'
            },
            {
                url: 'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1652392193/OpenGridComplex/Kobe_2_s98wnr.jpg'
            },
            {
                url: 'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1652392193/OpenGridComplex/Kobe_3_cxz6z8.jpg'
            }
        ]
    },
    "ViewInfoModal_C03": {
        title: "KID CUDI x NIGO",
        date: "SEPT 2019",
        photographer: 'COMPLEX',
        description: "Kid Cudi, who met NIGO for the first time on a trip to Japan for this cover, told us that he “begged NIGO today to do something with Human Made” on the set of this shoot. In February 2022, Cudi launched his Members of the Rage clothing line designed in collaboration with Human Made. ",
        images: [
            {
                url: 'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1652392200/OpenGridComplex/CudiNigo_1_zbtqp9.jpg'
            },
            {
                url: 'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1652392200/OpenGridComplex/CudiNigo_2_kcqt9a.jpg'
            },
            {
                url: 'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1652392200/OpenGridComplex/CudiNigo_3_fvqgnh.jpg'
            }
        ]
    },
    "ViewInfoModal_C04": {
        title: "EMINEM",
        date: "DEC 2009/JAN 2010",
        photographer: 'MATT DOYLE',
        description: "Kanye West gave us props for our covers, including this Eminem image, on his blog KanyeUniverseCity.com. “I REALLY THINK COMPLEX IS TAKING THE COVER GAME TO ANOTHER LEVEL, THESE ARE ALL HISTORY IN THE MAKING,” he wrote.",
        images: [
            {
                url: 'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1652392205/OpenGridComplex/Eminem_1_eadw0f.jpg'
            },
            {
                url: 'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1652392205/OpenGridComplex/Eminem_2_zqjio2.jpg'
            },
            {
                url: 'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1652392205/OpenGridComplex/Eminem_3_rsssdq.jpg'
            }
        ]
    },
    "ViewInfoModal_C05": {
        title: "G.O.O.D Music",
        date: "AUG/SEPT 2012",
        photographer: 'NABIL ELDERKIN',
        description: "The cover, partly inspired by Vibe’s classic Death Row cover from 1996, was the first cover creative directed by Kanye West and DONDA.",
        images: [
            {
                url: 'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1652392210/OpenGridComplex/Good_1_dvtnxj.jpg'
            },
            {
                url: 'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1652392210/OpenGridComplex/Good_2_r8sfef.jpg'
            },
            {
                url: 'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1652392210/OpenGridComplex/Good_3_kww0bn.jpg'
            }
        ]
    },
    "ViewInfoModal_C06": {
        title: "JERRY LORENZO x JOHN MAYER",
        date: "NOV 2018",
        photographer: 'EMMAN MONTALVAN',
        description: "Nike eventually released the yellow “Amarillo” Air Fear of God 1s, first seen on this cover and originally not meant for general release, a few months after this cover was published.",
        images: [
            {
                url: 'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1652392215/OpenGridComplex/JerryJohn_1_tfe8nk.jpg'
            },
            {
                url: 'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1652392215/OpenGridComplex/JerryJohn_2_hpsfiz.jpg'
            },
            {
                url: 'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1652392215/OpenGridComplex/JerryJohn_3_gunked.jpg'
            }
        ]
    },
    "ViewInfoModal_C07": {
        title: "RIHANNA",
        date: "FEB/MAR 2013",
        photographer: 'ZOE MCCONNELL',
        description: `This issue had seven different covers, each one an "unapologetic" take on Rihannna's previous albums. She's the only celebrity with multiple Complex covers for a single issue.`,
        images: [
            {
                url: 'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1652392220/OpenGridComplex/Rihanna_1_llvknb.jpg'
            },
            {
                url: 'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1652392220/OpenGridComplex/Rihanna_2_yylfsd.jpg'
            },
            {
                url: 'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1652392220/OpenGridComplex/Rihanna_3_sovrnz.jpg'
            }
        ]
    },
    "ViewInfoModal_C08" : {
        title: "LIL WAYNE & TRAVIS BARKER",
        date: "DEC/JAN 2006",
        photographer: 'ROBERT MAXWELL',
        description: "Lil Wayne’s famous statement that he was “better than Jay-Z” came from this cover story. The explosive interview helped this issue become Complex’s 2nd-highest selling issue at the time.",
        images: [
            {
                url: 'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1652392285/OpenGridComplex/Wayne_1_ebpjpf.jpg'
            },
            {
                url: 'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1652392285/OpenGridComplex/Wayne_2_wybgxh.jpg'
            },
            {
                url: 'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1652392285/OpenGridComplex/Wayne_3_l8hmun.jpg'
            }
        ]
    },
    "ViewInfoModal_C09": {
        title: "A$AP ROCKY x JEREMY SCOTT",
        date: "FEB/MAR 2012",
        photographer: 'JUCO',
        description: "The visuals for our cover with ASAP Rocky and Jeremy Scott, who met for the first time ever on the set of this shoot, were a reference to the famous 1985 Andy Warhol and Jean-Michel Basquiat boxing poster.",
        images: [
            {
                url: 'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1652392294/OpenGridComplex/ASAP_1_xpcqjc.jpg'
            },
            {
                url: 'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1652392294/OpenGridComplex/ASAP_2_v6wjfb.jpg'
            },
            {
                url: 'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1652392294/OpenGridComplex/ASAP_3_ddpxer.jpg'
            }
        ]
    },
    "ViewInfoModal_C10": {
        title: "KANYE WEST",
        date: "AUG/SEPT 2007",
        photographer: 'JAMES DIMMOCK',
        description: "Kanye guest edited this August/September issue, his first time ever doing so, and appeared on the cover wearing a hot-pink G-Shock watch that became insanely popular after this issue hit newsstands. Call it The Kanye Effect. \
        We also revived “Fashion Beatdown,” a column we launched with the rapper back in 2004 where he critiques celebrities’ styles. This time, he gave himself a fashion beatdown.",
        images: [
            {
                url: 'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1652392301/OpenGridComplex/Kanye_01_sbf2qi.jpg'
            },
            {
                url: 'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1652392301/OpenGridComplex/Kanye_02_afggzo.jpg'
            },
            {
                url: 'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1652392301/OpenGridComplex/Kanye_03_dbz7ve.jpg'
            }
        ]
    },
    "ViewInfoModal_C11": {
        title: "CLIPSE x KAWS COVER",
        date: "OCT/NOV 2009",
        photographer: 'MATT DOYLE',
        illustrations: 'KAWS PHOTO',
        description: "Here’s a version of the cover by Kaws that never made it to the printer.",
        images: [
            {
                url: 'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1652392307/OpenGridComplex/ClipseKaws_1_rw8mzb.jpg'
            },
            {
                url: 'https://res.cloudinary.com/dq5lw8oy1/image/upload/v1652392308/OpenGridComplex/ClipseKaws_2_jcbzty.jpg'
            }
        ]
    },
}

const fontClass = {
    padding: '0 10px',
    letterSpacing: 0,
    lineHeight: 1,
    margin: 0
}

function OGInfoView(props) {

    const { id } = props;

    const cover = covers[id];

    // const product = useProduct(id);

    // React.useEffect(() => {
    //     if (product.status === 'success') {
    //         console.log(product.data);
    //     }
    // }, [product]);

    return (
        <Box minHeight='70vh' width='100%' maxWidth={450} container={true} textAlign='center' fontFamily="'Roboto Mono'" margin='auto' >
            <Box pt={2} maxWidth={isMobile ? 300 : '100%'} textAlign='left'>
                {cover.title &&
                    <Typography variant='h5' style={fontClass}>{cover.title}</Typography> }
                {cover.date &&
                    <Typography variant='h5' style={fontClass}>{cover.date}</Typography>}
            </Box>
            <Box py={2} maxWidth={isMobile ? '100%' : '100%'} textAlign='left'>
                {cover.photographer &&
                    <Typography variant="body1" style={fontClass}>PHOTOGRAPHER: {cover.photographer}</Typography> }
                {cover.illustrations &&
                    <Typography variant="body1" style={fontClass}>ILLUSTRATIONS: {cover.illustrations}</Typography>
                }
            </Box>
            <Box py={1} maxWidth={isMobile ? 310 : '100%'} textAlign='left'>
            {cover.description ?
                <Typography variant="body1" style={fontClass}>{cover.description}</Typography>
                :    
                <Typography variant="body1" style={fontClass}>Description</Typography>
                }
            </Box>
            {/* {product?.data?.externalUrl ?
                <Button variant='contained' style={{ margin: 10 }} onClick={() => window.open(product.data.externalUrl, '_blank')}>More Info</Button>
                :    
                <Button variant='contained' disabled style={{ margin: 10 }}>More Info</Button>
            } 
            {cover.images ?
                <img src={cover?.images[0].url} style={{ width: 300, height: 300 }} />
                :    
                <img src="https://images.unsplash.com/photo-1643114823006-a0fd52f70670?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=928&q=80" style={{ width: 300, height: 300 }} />
            }*/}

        <Box p={2} maxWidth={isMobile ? 'calc(100% - 20px)' : 900} textAlign='center' margin='auto'>
        {cover.images &&
            // <SimpleImageSlider
            //   width={isMobile ? 'calc(100% - 40px)' : "100%"}
            //   height={isMobile ? 'calc(100% - 40px)' : '100%'}
            //     images={cover.images || []}
            //     navSize={25}
            //     // navMargin={10}
            //     navStyle={2}
            // //   showBullets={true}
            //   showNavs={true}
            //   />
                    <Carousel
                        showThumbs={false}
                        showStatus={false}
                        showIndicators={false}
                        infiniteLoop={true}
                        style={{padding: 10}}
                    >
                        {cover?.images?.map(image => (<>
                            <img src={image.url} height={'100%'} width={'100%'} />
                        </>))}
                    </Carousel>
            }
            </Box>
        </Box>
  )
}

export default OGInfoView