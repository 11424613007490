import React from "react";
import LegalPage from "./legal";
import { Switch, Route, Router } from "./../util/router";
import NotFoundPage from "./404";
import { ThemeProvider } from "./../util/theme";
import { QueryClientProvider } from "./../util/db";
import OpenGridContainer from "../components/OpenGrid/OpenGridContainer";
import { Dialog } from "@material-ui/core";
import TagManager from 'react-gtm-module';
import Client from 'shopify-buy';

// const client = Client.buildClient({
//   storefrontAccessToken: process.env.REACT_APP_STOREFRONT_API_CLIENT_TOKEN,
//   domain: 'shop.complex.com',
//   'Content-Type': 'application/graphql',
// });



function App() {

  React.useEffect(() => {
    TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_TAG })
  }, []);
  
  return (
    // FOR IFRAME ONLY EXPERIENCES
    // <>{
    //   window.self === window.top ? (window.location.replace('https://complexland.com'))
    //     :
    <>
        <QueryClientProvider>
          <ThemeProvider>
            <Router>
                  <>
                <OpenGridContainer
                  // client={client}
                />
                
                    <Switch>
                      <Route exact path="/" component={null} />

                      <Route exact path="/legal/:section" component={LegalPage} />

                      <Route component={NotFoundPage} />
                    </Switch>

                  </>
            </Router>
          </ThemeProvider>
        </QueryClientProvider>
    </>
    );
}

export default App;
