import React from "react";
import ReactDom from "react-dom";
import App from "./pages/_app";
import * as serviceWorker from "./serviceWorker";


// import LogRocket from 'logrocket';
// import setupLogRocketReact from 'logrocket-react';

// LogRocket.init('mahgfv/devhmh', {
//   browser: {
//     urlSanitizer: url => {
//       let sanitizedUrl = url;

//       // redact the path following /ssn/ from the URL
//       sanitizedUrl = sanitizedUrl.replace(/\/ssn\/([^\/]*)/i, '/ssn/**redacted**');

//       // redact the value of the query parameter secret_key
//       sanitizedUrl = sanitizedUrl.replace(/secret_key=([^&]*)/, 'secret_key=**redacted**');

//       // make sure you return the sanitized URL string
//       return sanitizedUrl;
//     },
//   }, network: {
//     requestSanitizer: request => {
//       // if the url contains 'delete'
//       if (request.url.toLowerCase().indexOf('delete') !== -1) {
//         // scrub out the body
//         request.body = null;
//       }

//       // if the url contains 'ignore'
//       if (request.url.toLowerCase().indexOf('ignore') !== -1) {
//         // ignore the request response pair
//         return null;
//       }

//       // scrub header value from request
//       if (request.headers['x-auth-token']) {
//         request.headers['x-auth-token'] = '';
//       }

//       // scrub header pair from request
//       request.headers['x-secret'] = null;

//       // make sure you return the modified request
//       return request;
//     },
//     responseSanitizer: response => {
//       if (response.headers['x-secret']) {
//         // removes all response data
//         return null;
//       }

//       // scrubs response body
//       response.body = null;
//       return response;
//     },

//   }
// });
// setupLogRocketReact(LogRocket);

  
ReactDom.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
