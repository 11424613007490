import React from 'react';
import { useEvent } from '../../hooks';
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { Link } from "../../util/router";
import { Fullscreen, FullscreenExit } from "@material-ui/icons";
import OGInfoView from './OGInfoView';
// import OGProductView from './OGProductView';
import { ogLog } from '../../util/db';
import { Box, Typography, Button, Grid } from '@material-ui/core';
import LegalTerms from '../Legal/LegalTerms';
import LegalPrivacy from '../Legal/LegalPrivacy';

const images = {
    "complex-20th-anniversary-maneki-neko-tote-bag-designed-by-nigo": "https://res.cloudinary.com/dq5lw8oy1/image/upload/v1654185333/OpenGridComplex/SoldOut_Tote_jtpkak.jpg",
    "complex-20th-anniversary-maneki-neko-long-sleeve-t-shirt-designed-by-nigo": "https://res.cloudinary.com/dq5lw8oy1/image/upload/v1654185333/OpenGridComplex/SoldOut_LongSleeve_flzvtm.jpg",
    "complex-20th-anniversary-maneki-neko-hoodie-designed-by-nigo": "https://res.cloudinary.com/dq5lw8oy1/image/upload/v1654185333/OpenGridComplex/SoldOut_Hoodie_soldct.jpg",
    "complex-20th-anniversary-maneki-neko-t-shirt-designed-by-nigo": "https://res.cloudinary.com/dq5lw8oy1/image/upload/v1654185333/OpenGridComplex/SoldOut_TShirt_pkok9h.jpg"
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const buttonStyle = { backgroundColor: "black", color: 'white', borderRadius: 30, margin: '30px 10px 0px' };

  
function OGDialogBox(props) {
    const { unfocus, refocus, client, checkoutId, setCheckoutID, checkoutUrl, setCheckoutUrl, checkout, setCheckout } = props;
    const [open, setOpen] = React.useState(false);
    const [fullScreen, setFullScreen] = React.useState(false);
    const [modalID, setModalID] = React.useState(null);
    const [docID, setDocId] = React.useState('');
    const [analyticsId, setAnalyticsId] = React.useState('');
    const [justExited, setJustExited] = React.useState(null);
    
    const handleModal = (mid, aid = '', did = '') => {
        if (!justExited) {

            setModalID(mid);
            if (did !== '') setDocId(did);
            setOpen(true);
            unfocus();
            if (aid !== '') {
                ogLog('open_' + aid);
                setAnalyticsId(aid);
            }
        }
    }

    const handleClose = () => {
        ogLog('close_' + analyticsId);
        setJustExited(true);
        setOpen(false);
        setModalID('');
        refocus();
        setTimeout(() => setJustExited(false), 500);
    }

    const handleCheckout = () => {
        ogLog('click_checkout_from_cart');
        window.open(checkoutUrl, 'https://shop.complex.com');

    }
    
    useEvent('open_web_cart', () => handleModal('open_web_cart', 'cart'));
    useEvent('ViewOpenGrid', () => window.open('https://opengrid.xyz/?source=twenty'));
    useEvent('ViewPrivacyPolicy', () => handleModal('ViewPrivacyPolicy', 'privacy_policy'));
    useEvent('ViewTerms', () => handleModal('ViewTerms', 'terms'));
    useEvent('ViewInfoModal_C01', () => handleModal('info_modal', 'cover_WEEKEND', "ViewInfoModal_C01"));
    useEvent('ViewInfoModal_C02', () => handleModal('info_modal', 'cover_KOBE', "ViewInfoModal_C02"));
    useEvent('ViewInfoModal_C03', () => handleModal('info_modal', 'cover_CUDIxNIGO', "ViewInfoModal_C03"));
    useEvent('ViewInfoModal_C04', () => handleModal('info_modal', 'cover_EMINEM', "ViewInfoModal_C04"));
    useEvent('ViewInfoModal_C05', () => handleModal('info_modal', 'cover_GOOD', "ViewInfoModal_C05"));
    useEvent('ViewInfoModal_C06', () => handleModal('info_modal', 'cover_MAYER', "ViewInfoModal_C06"));
    useEvent('ViewInfoModal_C07', () => handleModal('info_modal', 'cover_RIHANNA', "ViewInfoModal_C07"));
    useEvent('ViewInfoModal_C08', () => handleModal('info_modal', 'cover_WAYNE', "ViewInfoModal_C08"));
    useEvent('ViewInfoModal_C09', () => handleModal('info_modal', 'cover_ASAP', "ViewInfoModal_C09"));
    useEvent('ViewInfoModal_C10', () => handleModal('info_modal', 'cover_KANYE', "ViewInfoModal_C10"));
    useEvent('ViewInfoModal_C11', () => handleModal('info_modal', 'cover_CLIPSE', "ViewInfoModal_C11"));
    useEvent('ViewInfoModal_S01', () => handleModal('ViewInfoModal_S01', 'product_TOTE', "complex-20th-anniversary-maneki-neko-tote-bag-designed-by-nigo"));
    useEvent('ViewInfoModal_S02', () => handleModal('ViewInfoModal_S02', 'product_LONG_SLEEVE', "complex-20th-anniversary-maneki-neko-long-sleeve-t-shirt-designed-by-nigo"));
    useEvent('ViewInfoModal_S03', () => handleModal('ViewInfoModal_S03', 'product_HOODIE', "complex-20th-anniversary-maneki-neko-hoodie-designed-by-nigo"));
    useEvent('ViewInfoModal_S04', () => handleModal('ViewInfoModal_S04', 'product_TSHIRT', "complex-20th-anniversary-maneki-neko-t-shirt-designed-by-nigo"));

    return (
        <Dialog fullScreen={fullScreen}  open={open} onClose={handleClose} TransitionComponent={Transition} style={{backgroundColor: 'transparent'}}>
        {/* <FullscreenModal srcUrl="https://uploads-ssl.webflow.com/61b13cc90b0faf680424bc2d/61b3c8374111920d80d2b8dc_OG_Website_Video_Landing_Header_112921-transcode.mp4" closeModal={() => setOpen(false)} /> */}

            {modalID === 'open_web_cart' && (
                // <OGSlider images={[
                //     { url: 'https://images.unsplash.com/photo-1643114823006-a0fd52f70670?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=928&q=80' },
                //     { url: 'https://firebasestorage.googleapis.com/v0/b/opengrid-retail.appspot.com/o/products%2F123123123_200x200.png?alt=media' },
                // ]}
                //     height={window.innerHeight / 1.5}
                //     width={window.innerWidth * 0.8}
                //     fullScreen={fullScreen}
                // />
                
                <>
                    <Box py={3}>
                        <Typography variant="h6" align='center'>Cart</Typography>

                        {checkout && checkout?.lineItems?.length > 0 ? checkout.lineItems.map(item => (<>
                            <Box key={item.id} p={2}>
                                <Grid container alignItems="center">
                                    <Grid item xs={4}>
                                        <img src={item.variant.image.src} width={'100%'}/>
                                    </Grid>
                                    <Grid item xs={8} justifyContent="flex-start" >
                                        <Typography variant="body1">{item.title}</Typography>
                                        <Typography variant="body1" >Quantity: { item.quantity }</Typography>
                                        <Typography variant="body1">{ item.variant.title }</Typography>
                                        <Typography variant="body1">Price: $ { item.variant.price }</Typography>
                                    </Grid>
                                </Grid>
                                <Button style={{float: 'right', color: 'red'}} onClick={() => client.checkout.removeLineItems(checkoutId, item.id).then(checkout => setCheckout(checkout))}>
                                    Remove
                                </Button>
                            </Box>
                        </>))
                        :
                            <Box p={3}>
                                <Typography>Your cart is empty.</Typography>
                        </Box>
                        }
                        {checkout?.totalPrice && <Typography style={{ padding: 15 }}>Total: ${checkout.totalPrice}</Typography>}
                        {checkout?.lineItems?.length > 0 && <Button size="medium"
                            variant="contained"
                            onClick={handleCheckout}
                            style={buttonStyle}>Checkout</Button>}
                    </Box>
                </>
            )}


            {modalID === 'info_modal' && <OGInfoView  id={docID}  /> }


            {modalID && modalID.includes('ViewInfoModal_S') &&
                <Box margin={'auto'}>
                    <img src={images[docID]} height='100%' width='100%' maxWidth='600px' maxHeight='600px' style={{ marginBottom: -5}} />
                </Box> 
                // <OGProductView
                // handle={docID} client={client}
                // checkoutId={checkoutId} setCheckoutID={setCheckoutID}
                // checkoutUrl={checkoutUrl} setCheckoutUrl={setCheckoutUrl}
                // checkout={checkout}
                // setCheckout={setCheckout}
                // />
            }

            {modalID === 'ViewTerms' && (
                <Box p={3}>
                    <LegalTerms />
                </Box>
            )}
            {modalID === 'ViewPrivacyPolicy' && (
                <Box p={3}>
                    <LegalPrivacy />
                </Box>
            )}

            {/* {modalID === 'handle_token' && (
                <OGDynamicContent setMessage={props.setMessage} handleClose={handleClose} />
            ) } */}
        
            {open && <>
                <IconButton style={{ position: 'absolute', top: '5px', right: '5px', zIndex: 1000, color: 'white' }} component={Link} to='/' onClick={handleClose}>
                    <CloseIcon fontSize='large' style={{ position: 'fixed', zIndex: 1000, color: 'gray' }} />
                </IconButton>
                <IconButton style={{ position: 'absolute', top: '5px', right: '40px', zIndex: 1000, color: 'white' }}  onClick={() => setFullScreen(!fullScreen)}>
                    {!fullScreen ?
                        <Fullscreen fontSize='large' style={{ position: 'fixed',  zIndex: 1000, color: 'gray' }} />
                    :
                        <FullscreenExit fontSize='large' style={{ position: 'fixed', zIndex: 1000, color: 'gray' }} />
                    }
                </IconButton>
            </>}
    </Dialog>
  )
}

export default OGDialogBox