import React from 'react'
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

function OGConfirmationModal(props) {
    const { title, description, link } = props;

  return (
    <Dialog open={true}>
           <DialogTitle id="responsive-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent style={{maxWidth: '90%'}} >
            <DialogContentText>
            <p variant="subtitle1">Visit <a href="https://shop.complex.com/collections/20-years-of-complex-maneki-neko-designed-by-nigo">HERE</a> to shop our special 20 Years of Complex merchandise designed by NIGO®. </p>
        </DialogContentText>

            </DialogContent>
          <DialogActions style={{ justifyContent: 'flex-start', paddingLeft: '16px'}}>
          <a href="https://complexland.com" target="https://complexland.com" style={{ textDecoration: 'none' }}>
            <Button>
                Back to ComplexLand
            </Button>
        </a>
              <a href={link} style={{ textDecoration: 'none' }}>
                <Button >
                Shop
            </Button></a>
            </DialogActions>
    </Dialog>
  )
}

export default OGConfirmationModal